"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HUMAN_READABLE_REJECTION_REASON = exports.CustomDocumentRequestAttributeKey = exports.SingleDocumentUploadRequestStatus = exports.PendingDocumentTaskType = exports.PendingDocumentTaskStatus = void 0;
const incomeVerification_1 = require("./incomeVerification");
// These status should be irreversible so pending => inProgress => completed. Pending/In progress => abandoned. Completed/Abandoned are terminal states
var PendingDocumentTaskStatus;
(function (PendingDocumentTaskStatus) {
    // The request is sent to customer but no action is taken by customer yet
    PendingDocumentTaskStatus["pending"] = "pending";
    // Customer has uploaded at least 1 document that needs to be reviewed by Aven
    PendingDocumentTaskStatus["inProgress"] = "in_progress";
    // The task is completed and no further action is allowed
    PendingDocumentTaskStatus["completed"] = "completed";
    // The task is abandoned and no further action is allowed
    PendingDocumentTaskStatus["abandoned"] = "abandoned";
})(PendingDocumentTaskStatus = exports.PendingDocumentTaskStatus || (exports.PendingDocumentTaskStatus = {}));
var PendingDocumentTaskType;
(function (PendingDocumentTaskType) {
    PendingDocumentTaskType["incomeVerification"] = "income_verification";
    PendingDocumentTaskType["fraud"] = "fraud";
    PendingDocumentTaskType["cip"] = "cip";
})(PendingDocumentTaskType = exports.PendingDocumentTaskType || (exports.PendingDocumentTaskType = {}));
// These status should be irreversible so pending => inProgress => completed. Pending/In progress => abandoned. Completed/abandoned are terminal states
var SingleDocumentUploadRequestStatus;
(function (SingleDocumentUploadRequestStatus) {
    // The request is sent to customer but no action is taken by customer yet
    SingleDocumentUploadRequestStatus["pending"] = "pending";
    // Customer has uploaded the document and needs to be reviewed by Aven
    SingleDocumentUploadRequestStatus["inProgress"] = "in_progress";
    // The document review is completed by Aven. Could be approved or denied. one and only one of [approved_at, denied_at] should be set
    SingleDocumentUploadRequestStatus["completed"] = "completed";
    // The task is abandoned and no further action is allowed
    SingleDocumentUploadRequestStatus["abandoned"] = "abandoned";
})(SingleDocumentUploadRequestStatus = exports.SingleDocumentUploadRequestStatus || (exports.SingleDocumentUploadRequestStatus = {}));
var CustomDocumentRequestAttributeKey;
(function (CustomDocumentRequestAttributeKey) {
    // specifies a doc year, applicable for incomeVerification
    CustomDocumentRequestAttributeKey["year"] = "year";
})(CustomDocumentRequestAttributeKey = exports.CustomDocumentRequestAttributeKey || (exports.CustomDocumentRequestAttributeKey = {}));
exports.HUMAN_READABLE_REJECTION_REASON = {
    [incomeVerification_1.IncomeVerificationDocumentPendingReason.INCORRECT_DOC_TYPE]: 'it was not an acceptable document type',
    [incomeVerification_1.IncomeVerificationDocumentPendingReason.INCOMPLETE_PAGE]: 'part of a page was missing',
    [incomeVerification_1.IncomeVerificationDocumentPendingReason.NO_FILLED_DATA]: 'some data was missing',
    [incomeVerification_1.IncomeVerificationDocumentPendingReason.OUTDATED_DOC]: 'it was outdated',
    [incomeVerification_1.IncomeVerificationDocumentPendingReason.MISSING_INFO]: 'some data was missing',
    [incomeVerification_1.IncomeVerificationDocumentPendingReason.MISMATCHED_INFO]: 'the data did not match our records',
    [incomeVerification_1.IncomeVerificationDocumentPendingReason.CROPPED_BLURRY]: 'it was blurry or cropped',
    [incomeVerification_1.IncomeVerificationDocumentPendingReason.PICTURE_OF_SCREEN]: 'the picture was not legible',
    [incomeVerification_1.IncomeVerificationDocumentPendingReason.ALL_PAGES_OF_BANK_STATEMENTS_REQUIRED]: 'pages were missing',
    [incomeVerification_1.IncomeVerificationDocumentPendingReason.DOC_TYPE_NOT_ALLOWED]: 'it was not an acceptable document type',
    [incomeVerification_1.IncomeVerificationDocumentPendingReason.PASSWORD_PROTECTED_DOC]: 'it was password protected',
    [incomeVerification_1.IncomeVerificationDocumentPendingReason.OCROLUS_UNSUPPORTED_DOC]: 'it was not an acceptable document type',
};
