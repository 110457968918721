"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AciPaymentFrontendError = exports.PaymentErrorSource = void 0;
var PaymentErrorSource;
(function (PaymentErrorSource) {
    PaymentErrorSource["ACI"] = "ACI";
    PaymentErrorSource["CoreCard"] = "CoreCard";
    PaymentErrorSource["UserPaymentMoreThanBalance"] = "UserPaymentMoreThanBalance";
    PaymentErrorSource["TimingOfPayment"] = "TimingOfPayment";
})(PaymentErrorSource = exports.PaymentErrorSource || (exports.PaymentErrorSource = {}));
var AciPaymentFrontendError;
(function (AciPaymentFrontendError) {
    AciPaymentFrontendError["RETRY_DEBIT"] = "RETRY_DEBIT";
    AciPaymentFrontendError["SCHEDULE_CALL"] = "SCHEDULE_CALL";
    AciPaymentFrontendError["DUPLICATE_PAYMENT"] = "DUPLICATE_PAYMENT";
    AciPaymentFrontendError["RETRY_PAYMENT_SAME_PAYMENT_METHOD"] = "RETRY_PAYMENT_SAME_PAYMENT_METHOD";
    AciPaymentFrontendError["RETRY_PAYMENT_WITH_NEW_PAYMENT_SOURCE"] = "RETRY_PAYMENT_WITH_NEW_PAYMENT_SOURCE";
    AciPaymentFrontendError["DEBIT_CARD_DECLINED"] = "DEBIT_CARD_DECLINED";
    AciPaymentFrontendError["DEBIT_CARD_INVALID_CARD_NUMBER"] = "DEBIT_CARD_INVALID_CARD_NUMBER";
    AciPaymentFrontendError["DEBIT_CARD_NSF"] = "DEBIT_CARD_NSF";
    AciPaymentFrontendError["DEBIT_CARD_WITHDRAWAL_LIMIT"] = "DEBIT_CARD_WITHDRAWAL_LIMIT";
    AciPaymentFrontendError["DEBIT_CARD_INVALID_AMOUNT"] = "DEBIT_CARD_INVALID_AMOUNT";
})(AciPaymentFrontendError = exports.AciPaymentFrontendError || (exports.AciPaymentFrontendError = {}));
