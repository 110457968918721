"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WebViewMessageEventName = void 0;
// next time we update aven_score, we need to update that file to refer to here
var WebViewMessageEventName;
(function (WebViewMessageEventName) {
    WebViewMessageEventName["REQUEST_TRACKING_PERMISSION"] = "REQUEST_TRACKING_PERMISSION";
    WebViewMessageEventName["REQUEST_PUSH_PERMISSION"] = "REQUEST_PUSH_PERMISSION";
    WebViewMessageEventName["GET_PUSH_ENABLED"] = "GET_PUSH_ENABLED";
    WebViewMessageEventName["SAVE_ACCESS_TOKEN"] = "SAVE_ACCESS_TOKEN";
    // Todo SAVE_SESSION_ACCESS_TOKEN is deprecated, but we have to keep it around for backwards compat.
    // Native version 2.3.12 (78) and below use it.
    WebViewMessageEventName["SAVE_SESSION_ACCESS_TOKEN"] = "SAVE_SESSION_ACCESS_TOKEN";
    WebViewMessageEventName["SAVE_SESSION_INFO"] = "SAVE_SESSION_INFO";
    WebViewMessageEventName["SAVE_AND_SHARE_DOCUMENT"] = "SAVE_AND_SHARE_DOCUMENT";
    WebViewMessageEventName["MAYBE_SEND_FACEBOOK_APP_EVENT"] = "MAYBE_SEND_FACEBOOK_APP_EVENT";
    WebViewMessageEventName["LOG_AMPLITUDE_EVENT"] = "LOG_AMPLITUDE_EVENT";
    WebViewMessageEventName["RETRIEVE_CONTACTS"] = "RETRIEVE_CONTACTS";
    WebViewMessageEventName["OPEN_EXTERNAL_WEB_VIEW"] = "OPEN_EXTERNAL_WEB_VIEW";
    WebViewMessageEventName["OPEN_SHARE_SHEET"] = "OPEN_SHARE_SHEET";
    WebViewMessageEventName["CLOSE_EXTERNAL_WEB_VIEW"] = "CLOSE_EXTERNAL_WEB_VIEW";
    WebViewMessageEventName["SAVE_PLAID_LINK_TOKEN"] = "SAVE_PLAID_LINK_TOKEN";
    WebViewMessageEventName["GET_PLAID_LINK_TOKEN"] = "GET_PLAID_LINK_TOKEN";
    WebViewMessageEventName["SEND_PLAID_LINK_SUCCESS_DATA"] = "SEND_PLAID_LINK_SUCCESS_DATA";
    WebViewMessageEventName["SET_PERSISTED_WEB_STORE_VALUE"] = "SET_PERSISTED_WEB_STORE_VALUE";
    WebViewMessageEventName["WEB_IS_INTERACTIVE"] = "WEB_IS_INTERACTIVE";
    WebViewMessageEventName["SAVE_URL_PATH"] = "SAVE_URL_PATH";
    WebViewMessageEventName["SET_DEVELOPER_MODE"] = "SET_DEVELOPER_MODE";
    WebViewMessageEventName["RETRIEVE_DEVELOPER_MODE_STATUS"] = "RETRIEVE_DEVELOPER_MODE_STATUS";
    WebViewMessageEventName["TOAST_MESSAGE"] = "TOAST_MESSAGE";
    WebViewMessageEventName["SET_NUX_TEST_MODE"] = "SET_NUX_TEST_MODE";
    WebViewMessageEventName["RETRIEVE_NUX_TEST_MODE_STATUS"] = "RETRIEVE_NUX_TEST_MODE_STATUS";
    WebViewMessageEventName["SET_NOTIF_PERM_ASKED_TIME"] = "SET_NOTIF_PERM_ASKED_TIME";
    WebViewMessageEventName["GET_NOTIF_PERM_ASKED_TIME"] = "GET_NOTIF_PERM_ASKED_TIME";
    WebViewMessageEventName["GET_CAN_ASK_PUSH_AGAIN"] = "GET_CAN_ASK_PUSH_AGAIN";
    WebViewMessageEventName["TRY_SEND_APPSFLYER_SIGN_UP_EVENT"] = "TRY_SEND_APPSFLYER_SIGN_UP_EVENT";
    WebViewMessageEventName["TRY_SEND_APPSFLYER_HOMEOWNER_SIGN_UP_EVENT"] = "TRY_SEND_APPSFLYER_HOMEOWNER_SIGN_UP_EVENT";
    WebViewMessageEventName["TRY_SEND_APPSFLYER_HELOC_SIGN_UP_EVENT"] = "TRY_SEND_APPSFLYER_HELOC_SIGN_UP_EVENT";
    WebViewMessageEventName["TRY_SEND_APPSFLYER_CREDIT_CARD_SIGN_UP_EVENT"] = "TRY_SEND_APPSFLYER_CREDIT_CARD_SIGN_UP_EVENT";
    WebViewMessageEventName["SET_PLAID_LOW_BALANCE_ALERT_THRESHOLD"] = "SET_PLAID_LOW_BALANCE_ALERT_THRESHOLD";
    WebViewMessageEventName["GET_PLAID_LOW_BALANCE_ALERT_THRESHOLD"] = "GET_PLAID_LOW_BALANCE_ALERT_THRESHOLD";
    WebViewMessageEventName["OPEN_SMS_SHARE_SHEET"] = "OPEN_SMS_SHARE_SHEET";
    WebViewMessageEventName["OPEN_MAIL_SHARE_SHEET"] = "OPEN_MAIL_SHARE_SHEET";
    WebViewMessageEventName["GET_EXPO_UPDATE_INFO"] = "GET_EXPO_UPDATE_INFO";
    WebViewMessageEventName["GET_PERSISTED_WEB_STORE"] = "GET_PERSISTED_WEB_STORE";
    WebViewMessageEventName["OPEN_APP_SETTINGS"] = "OPEN_APP_SETTINGS";
    WebViewMessageEventName["CAN_ASK_FOR_CONTACT_PERMISSION_AGAIN"] = "CAN_ASK_FOR_CONTACT_PERMISSION_AGAIN";
    WebViewMessageEventName["SET_APPLY_SAFE_AREA"] = "SET_APPLY_SAFE_AREA";
})(WebViewMessageEventName = exports.WebViewMessageEventName || (exports.WebViewMessageEventName = {}));
