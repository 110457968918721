<template>
    <div id="app">
        <router-view :key="$route.path" />
    </div>
</template>

<script>
    import Vue from 'vue'
    import { setLocalMetadata } from '@/services/marketing'
    import { runWithRetryLogic } from '@/utils/http-client'

    export default Vue.extend({
        name: 'App',
        metaInfo: {
            titleTemplate: (titleChunk) => {
                // If undefined or blank then we don't need the hyphen
                return titleChunk ? `${titleChunk} | Aven Card` : 'Aven HELOC Card: A Credit Card backed by Home Equity'
            },
            meta: [
                {
                    name: 'description',
                    vmid: 'description',
                    content: 'Unlock the power of home equity to get insanely low credit card rates. Stop worrying about promo rates expiring. Save every single month, forever.',
                },
            ],
        },
        created() {
            // Set Default Cookie Settings
            Vue.$cookies.config('30d', '', `${process.env.VUE_APP_COOKIES_URL}`, true, 'Strict')
            this.$cookies.set('locale', 'en')
        },
        mounted() {
            runWithRetryLogic(setLocalMetadata, 2)
        },
    })
</script>

<style lang="scss">
    @import 'node_modules/aven_shared/src/styles/main.scss';
    @import '@/styles/tailwind.scss';

    //@import 'styles/main.scss';
</style>
