"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApplicantType = void 0;
var ApplicantType;
(function (ApplicantType) {
    ApplicantType["primary"] = "primary";
    ApplicantType["coApplicant"] = "coApplicant";
    ApplicantType["coOwner"] = "coOwner";
    ApplicantType["livingTrustMember"] = "livingTrustMember";
})(ApplicantType = exports.ApplicantType || (exports.ApplicantType = {}));
