import { appSessionStorage, sessionStorageKey } from '@/utils/storage'
import { logger } from '@/utils/logger'
import { pifForAllPagePaths } from '@/routes/pifForAllRoutes'
import { marketingPagePaths } from '@/routes/marketingRoutes'
import { maybeSetUnderwritingMetadata } from '@/utils/uwMetaData'

// Subset of AvenProperty in aven_backend/src/entity/analytics.ts
export enum AvenProperty {
    /**
     * Sessions created for Aven card applicants/applications.
     */
    ORIGINATION = 'ORIGINATION',
    /**
     * Sessions created for non-customers when they begin the flow to create a PIF code.
     * See landing page PifForAllLandingPage.vue
     */
    PIF_FOR_ALL_SENDER = 'PIF_FOR_ALL_SENDER',
    /**
     * Sessions created for advisor users when they begin to the flow to create an Advisor account.
     * See landing page Advisor.vue
     */
    ADVISOR = 'ADVISOR',
}

const getAvenPropertyForSessionCreation = (): AvenProperty => {
    if (window.location.pathname === pifForAllPagePaths.PIF_FOR_ALL_LANDING) {
        return AvenProperty.PIF_FOR_ALL_SENDER
    } else if (window.location.pathname === marketingPagePaths.ADVISOR) {
        return AvenProperty.ADVISOR
    }
    return AvenProperty.ORIGINATION
}

export const getMetadata = () => {
    const avenProperty = getAvenPropertyForSessionCreation()
    return {
        resolution: window.innerWidth + 'x' + window.innerHeight,
        cookies: document.cookie,
        // Pass raw query string to allow backend to parse however it likes
        queryParams: window.location.search,
        path: window.location.pathname,
        avenProperty,
        referrer: document.referrer,
        referringDomain: document.referrer ? new URL(document.referrer).hostname : undefined,
    }
}

export const fireSessionIdRequest = (): XMLHttpRequest => {
    const sessionIdReadyEvent = new Event('sessionIdReady')

    const req = new XMLHttpRequest()
    req.onreadystatechange = function () {
        // When request ready + successful
        if (this.readyState === XMLHttpRequest.DONE && this.status === 200) {
            const resp = JSON.parse(this.responseText)
            console.log('Setting session details:', resp.payload)
            // session ID MUST be set first
            appSessionStorage.setItem(sessionStorageKey.sessionId, resp.payload.sessionId)
            appSessionStorage.setItem(sessionStorageKey.sessionAccessJWT, resp.payload.jwt.sessionAccessJWT)
            appSessionStorage.setItem(sessionStorageKey.experimentsOverrides, JSON.stringify(resp.payload.experimentsOverrides || {}))
            maybeSetUnderwritingMetadata(resp.payload.underwritingMetadata)

            appSessionStorage.setItem(sessionStorageKey.creditCardMarketData, JSON.stringify(resp.payload.creditCardMarketData || {}))
            window.dispatchEvent(sessionIdReadyEvent)
        }
        // We abort the request in main.ts abortCallIfSessionIdNotCompleteWithinXSeconds() if it takes too long.
        else if (this.readyState === XMLHttpRequest.DONE && this.status === 0) {
            logger.error(`getSessionId request was aborted (usually because the request took too long)`)
        } else if (this.readyState === XMLHttpRequest.DONE) {
            throw new Error('getSessionId call failed with status ' + this.status)
        }
    }
    req.open('POST', `${process.env.VUE_APP_API_BASE_URL}/ana/session`)
    req.setRequestHeader('Content-Type', 'application/json')

    if (process.env.VUE_APP_NODE_ENV !== 'production') {
        const abTestOverrides = appSessionStorage.getItem(sessionStorageKey.abTestOverrides)
        req.setRequestHeader('X-AB-Test-Overrides', abTestOverrides || '')
    }

    console.log('Requesting sessionId + experimentName, also sending metadata')
    const body = JSON.stringify(getMetadata())
    setTimeout(function () {
        req.send(body)
    }, 100)

    return req
}

const invalidValues = ['undefined', 'null', '[object Object]']
const existsAndValid = (value: string | null | undefined) => {
    return value && !invalidValues.includes(value)
}

export const isSessionDataPresent = () => {
    return existsAndValid(appSessionStorage.getItem(sessionStorageKey.sessionId)) && existsAndValid(appSessionStorage.getItem(sessionStorageKey.sessionAccessJWT))
    // "undefined" is returned from appSessionStorage as a string if undefined was placed into it
}
