import { RouteConfig } from 'vue-router'

const routeName = 'retention'

export const pageNames = {
    PRE_QUALIFICATION: 'pre_qualification',
    BALANCE_SWEEP: 'balance_sweep',
    OFFER_PREVIEW: 'offer_preview',
    UPDATE_MARITAL_STATUS: 'update_marital_status',
    DOCUSIGN: 'docusign',
    WELCOME: 'welcome',
} as const

export const cliAndAprReductionAndBalanceSweepPagePaths = {
    PRE_QUALIFICATION: `/${routeName}/${pageNames.PRE_QUALIFICATION}`,
    BALANCE_SWEEP: `/${routeName}/${pageNames.BALANCE_SWEEP}`,
    OFFER_PREVIEW: `/${routeName}/${pageNames.OFFER_PREVIEW}`,
    UPDATE_MARITAL_STATUS: `/${routeName}/${pageNames.UPDATE_MARITAL_STATUS}`,
    DOCUSIGN: `/${routeName}/${pageNames.DOCUSIGN}`,
    SKIP_NOTARY_WELCOME: `/${routeName}/${pageNames.WELCOME}`,
}

const completionRoutes: RouteConfig[] = [
    {
        path: cliAndAprReductionAndBalanceSweepPagePaths.PRE_QUALIFICATION,
        component: () => import(/* webpackChunkName: "origination" */ '@/pages/churnRetention/PreQualification.vue'),
        meta: { transitionName: 'slide' },
    },
    {
        path: cliAndAprReductionAndBalanceSweepPagePaths.BALANCE_SWEEP,
        component: () => import(/* webpackChunkName: "origination" */ '@/pages/churnRetention/BalanceSweep.vue'),
        meta: { transitionName: 'slide' },
    },
    {
        path: cliAndAprReductionAndBalanceSweepPagePaths.OFFER_PREVIEW,
        component: () => import(/* webpackChunkName: "origination" */ '@/pages/churnRetention/OfferPreview.vue'),
        meta: { transitionName: 'slide' },
    },
    {
        path: cliAndAprReductionAndBalanceSweepPagePaths.DOCUSIGN,
        component: () => import(/* webpackChunkName: "origination" */ '@/pages/churnRetention/DocuSign.vue'),
        meta: { transitionName: 'slide' },
    },
    {
        path: cliAndAprReductionAndBalanceSweepPagePaths.SKIP_NOTARY_WELCOME,
        component: () => import(/* webpackChunkName: "origination" */ '@/pages/churnRetention/Welcome.vue'),
        meta: { transitionName: 'slide' },
    },
]

export const cliAndAprReductionAndBalanceSweepRoutes: RouteConfig[] = [...completionRoutes]
