import { RouteConfig } from 'vue-router'

const routeName = 'origination'

export const pageNames = {
    CONFIRM_DOB: 'confirm_dob',
    STATED_URGENCY: 'stated_urgency',
    STATED_USAGE: 'stated_usage',
    EMAIL_SSN: 'email_ssn',
    PERSONAL_INFO: 'personal_info',
    STATED_INCOME: 'stated_income',
    STATED_TOTAL_SAVINGS: 'stated_total_savings',
    SECONDARY_SIGNERS: 'secondary_signers',
    SECONDARY_SIGNERS_UPDATE: 'secondary_signers_update',
    DISPUTE_CO_OWNER_INFO: 'dispute_co_owner_info',
    HMDA: 'hmda',
    OFFER_PREVIEW: 'offer_preview',
    PRE_QUALIFICATION: 'pre_qualification',
    VERIFY_IDENTITY: 'identity',
    CO_APPLICANT_VERIFY_IDENTITY: 'co_applicant_identity',
    IDENTITY_QUESTIONS: 'identity_questions',
    CO_APPLICANT_IDENTITY_QUESTIONS: 'co_applicant_identity_questions',
    EMPLOYMENT_INCOME_VERIFICATION: 'employment_income_verification',
    CO_APPLICANT_EMPLOYMENT_INCOME_VERIFICATION: 'co_applicant_employment_income_verification',
    INCOME_VERIFICATION: 'income_verification',
    IRS_FORM_VERIFICATION: 'irs_form_verification',
    PAY_STUB_VERIFICATION: 'verify_pay_stubs',
    TAX_RETURN_VERIFICATION: 'verify_tax_return',
    OTHER_INCOME_VERIFICATION: 'verify_other_income',
    FORTIFIED_INCOME_VERIFICATION: 'verify_fortified_income',
    RENTAL_INCOME_VERIFICATION: 'rental_income_verification',
    CURRENT_RENT_VERIFICATION_SELECTION: 'current_rent_verification_selection',
    CURRENT_RENT_VERIFICATION_DOCUMENT_UPLOAD: 'current_rent_verification_document_upload',
    INCOME_VERIFICATION_IN_PROGRESS: 'verify_in_progress_income',
    INCOME_DOCUMENT_UPLOAD_PORTAL: 'income_document_upload_portal',
    AWAITING_MANUAL_DOCUMENT_VERIFICATION: 'awaiting_manual_document_verification',
    FLOOD_INSURANCE_VERIFICATION: 'flood_insurance_verification',
    REMEDY_NAME_MISMATCH: 'remedy_name_mismatch',
    SELECT_INCOME_VERIFICATION_METHOD: 'select_income_verification_method',
    PROVIDE_SUPPORTING_INFORMATION: 'supporting_information',
    EXPERIAN_FROZEN: 'credit_report_frozen',
    CO_APPLICANT_EXPERIAN_FROZEN: 'co_applicant_experian_frozen',
    OFFER_ADD_CO_APPLICANT_ON_FAILURE: 'offer_add_co_applicant_on_failure',
    ADD_CO_APPLICANT_INFO: 'add_co_applicant_information',
    SSN_VERIFICATION: 'ssn_verification',
    CO_APPLICANT_SSN_VERIFICATION: 'co_applicant_ssn_verification',
    TRUST_INFO_SUBMISSION: 'trust_info_submission',
    FORWARD_TO_NOTARIZATION: 'forward_to_notarization',
    DISPUTE_PROVIDER_DATA: 'disputeProviderData',
    IRS_FOR_ALL_PQ: 'irs_pre_qualification',
    IRS_FOR_ALL_IV: 'irs_income_verification',
    SELF_EMPLOYMENT_BUSINESS_PERCENTAGE: 'self_employment_business_percentage',
    LINE_DRAW_APR_REDUCTION: 'line_draw_apr_reduction',
    EARLY_CREDIT_CARD_CONFIRMATION: 'early_credit_card_confirmation',
    EARLY_CREDIT_CARD_RECEIVED: 'early_credit_card_received',
    FIRST_LIEN_ATTESTATION: 'first_lien_attestation',
    SECOND_LIEN_ATTESTATION: 'second_lien_attestation',
    MORTGAGE_INFO_ATTESTATION: 'mortgage_info_attestation',
} as const

export type originationPageNameKeys = keyof typeof pageNames
export type originationPageNameValues = typeof pageNames[originationPageNameKeys]

export const originationPagePaths = {
    CONFIRM_DOB: `/${routeName}/${pageNames.CONFIRM_DOB}`,
    STATED_URGENCY: `/${routeName}/${pageNames.STATED_URGENCY}`,
    STATED_USAGE: `/${routeName}/${pageNames.STATED_USAGE}`,
    EMAIL_SSN: `/${routeName}/${pageNames.EMAIL_SSN}`,
    PERSONAL_INFO: `/${routeName}/${pageNames.PERSONAL_INFO}`,
    STATED_INCOME: `/${routeName}/${pageNames.STATED_INCOME}`,
    STATED_TOTAL_SAVINGS: `/${routeName}/${pageNames.STATED_TOTAL_SAVINGS}`,
    SECONDARY_SIGNERS: `/${routeName}/${pageNames.SECONDARY_SIGNERS}`,
    SECONDARY_SIGNERS_UPDATE: `/${routeName}/${pageNames.SECONDARY_SIGNERS_UPDATE}`,
    DISPUTE_CO_OWNER_INFO: `/${routeName}/${pageNames.DISPUTE_CO_OWNER_INFO}`,
    HMDA: `/${routeName}/${pageNames.HMDA}`,
    OFFER_PREVIEW: `/${routeName}/${pageNames.OFFER_PREVIEW}`,
    PRE_QUALIFICATION: `/${routeName}/${pageNames.PRE_QUALIFICATION}`,
    VERIFY_IDENTITY: `/${routeName}/${pageNames.VERIFY_IDENTITY}`,
    CO_APPLICANT_VERIFY_IDENTITY: `/${routeName}/${pageNames.CO_APPLICANT_VERIFY_IDENTITY}`,
    IDENTITY_QUESTIONS: `/${routeName}/${pageNames.IDENTITY_QUESTIONS}`,
    CO_APPLICANT_IDENTITY_QUESTIONS: `/${routeName}/${pageNames.CO_APPLICANT_IDENTITY_QUESTIONS}`,
    EMPLOYMENT_INCOME_VERIFICATION: `/${routeName}/${pageNames.EMPLOYMENT_INCOME_VERIFICATION}`,
    INCOME_VERIFICATION: `/${routeName}/${pageNames.INCOME_VERIFICATION}`,
    IRS_FORM_VERIFICATION: `/${routeName}/${pageNames.IRS_FORM_VERIFICATION}`,
    PAY_STUB_VERIFICATION: `/${routeName}/${pageNames.PAY_STUB_VERIFICATION}`,
    TAX_RETURN_VERIFICATION: `/${routeName}/${pageNames.TAX_RETURN_VERIFICATION}`,
    OTHER_INCOME_VERIFICATION: `/${routeName}/${pageNames.OTHER_INCOME_VERIFICATION}`,
    FORTIFIED_INCOME_VERIFICATION: `/${routeName}/${pageNames.FORTIFIED_INCOME_VERIFICATION}`,
    RENTAL_INCOME_VERIFICATION: `/${routeName}/${pageNames.RENTAL_INCOME_VERIFICATION}`,
    CURRENT_RENT_VERIFICATION_SELECTION: `/${routeName}/${pageNames.CURRENT_RENT_VERIFICATION_SELECTION}`,
    CURRENT_RENT_VERIFICATION_DOCUMENT_UPLOAD: `/${routeName}/${pageNames.CURRENT_RENT_VERIFICATION_DOCUMENT_UPLOAD}`,
    INCOME_VERIFICATION_IN_PROGRESS: `/${routeName}/${pageNames.INCOME_VERIFICATION_IN_PROGRESS}`,
    INCOME_DOCUMENT_UPLOAD_PORTAL: `/${routeName}/${pageNames.INCOME_DOCUMENT_UPLOAD_PORTAL}`,
    AWAITING_MANUAL_DOCUMENT_VERIFICATION: `/${routeName}/${pageNames.AWAITING_MANUAL_DOCUMENT_VERIFICATION}`,
    FLOOD_INSURANCE_VERIFICATION: `/${routeName}/${pageNames.FLOOD_INSURANCE_VERIFICATION}`,
    REMEDY_NAME_MISMATCH: `/${routeName}/${pageNames.REMEDY_NAME_MISMATCH}`,
    SELECT_INCOME_VERIFICATION_METHOD: `/${routeName}/${pageNames.SELECT_INCOME_VERIFICATION_METHOD}`,
    PROVIDE_SUPPORTING_INFORMATION: `/${routeName}/${pageNames.PROVIDE_SUPPORTING_INFORMATION}`,
    EXPERIAN_FROZEN: `/${routeName}/${pageNames.EXPERIAN_FROZEN}`,
    CO_APPLICANT_EXPERIAN_FROZEN: `/${routeName}/${pageNames.CO_APPLICANT_EXPERIAN_FROZEN}`,
    OFFER_ADD_CO_APPLICANT_ON_FAILURE: `/${routeName}/${pageNames.OFFER_ADD_CO_APPLICANT_ON_FAILURE}`,
    ADD_CO_APPLICANT_INFO: `/${routeName}/${pageNames.ADD_CO_APPLICANT_INFO}`,
    SSN_VERIFICATION: `/${routeName}/${pageNames.SSN_VERIFICATION}`,
    CO_APPLICANT_SSN_VERIFICATION: `/${routeName}/${pageNames.CO_APPLICANT_SSN_VERIFICATION}`,
    TRUST_INFO_SUBMISSION: `/${routeName}/${pageNames.TRUST_INFO_SUBMISSION}`,
    FORWARD_TO_NOTARIZATION: `/${routeName}/${pageNames.FORWARD_TO_NOTARIZATION}`,
    DISPUTE_PROVIDER_DATA: `/${routeName}/${pageNames.DISPUTE_PROVIDER_DATA}`,
    IRS_FOR_ALL_PQ: `/${routeName}/${pageNames.IRS_FOR_ALL_PQ}`,
    IRS_FOR_ALL_IV: `/${routeName}/${pageNames.IRS_FOR_ALL_IV}`,
    SELF_EMPLOYMENT_BUSINESS_PERCENTAGE: `/${routeName}/${pageNames.SELF_EMPLOYMENT_BUSINESS_PERCENTAGE}`,
    LINE_DRAW_APR_REDUCTION: `/${routeName}/${pageNames.LINE_DRAW_APR_REDUCTION}`,
    EARLY_CREDIT_CARD_CONFIRMATION: `/${routeName}/${pageNames.EARLY_CREDIT_CARD_CONFIRMATION}`,
    EARLY_CREDIT_CARD_RECEIVED: `/${routeName}/${pageNames.EARLY_CREDIT_CARD_RECEIVED}`,
    FIRST_LIEN_ATTESTATION: `/${routeName}/${pageNames.FIRST_LIEN_ATTESTATION}`,
    SECOND_LIEN_ATTESTATION: `/${routeName}/${pageNames.SECOND_LIEN_ATTESTATION}`,
    MORTGAGE_INFO_ATTESTATION: `/${routeName}/${pageNames.MORTGAGE_INFO_ATTESTATION}`,
}

const dataCollectionRoutes: RouteConfig[] = [
    {
        path: originationPagePaths.STATED_URGENCY,
        component: () => import(/* webpackChunkName: "origination" */ '@/pages/origination/StatedUrgency.vue'),
        meta: { public: true },
    },
    {
        path: originationPagePaths.STATED_USAGE,
        component: () => import(/* webpackChunkName: "origination" */ '@/pages/origination/StatedUsage.vue'),
        meta: { public: true },
    },
    {
        path: originationPagePaths.EMAIL_SSN,
        component: () => import(/* webpackChunkName: "origination" */ '@/pages/origination/EmailSSN.vue'),
        meta: { public: true },
    },
    {
        path: originationPagePaths.PERSONAL_INFO,
        component: () => import(/* webpackChunkName: "origination" */ '@/pages/origination/PersonalInfo.vue'),
        props: true,
    },
    {
        path: originationPagePaths.SSN_VERIFICATION,
        component: () => import(/* webpackChunkName: "mailerOrigination" */ '@/pages/origination/SSNVerification.vue'),
    },
    {
        path: originationPagePaths.CO_APPLICANT_SSN_VERIFICATION,
        component: () => import(/* webpackChunkName: "mailerOrigination" */ '@/pages/origination/SSNVerification.vue'),
        props: { isCoApplicant: true },
    },
    {
        path: originationPagePaths.OFFER_ADD_CO_APPLICANT_ON_FAILURE,
        component: () => import('@/pages/origination/OfferAddCoApplicantOnFailure.vue'),
        meta: { transitionName: 'slide' },
    },
    {
        path: originationPagePaths.ADD_CO_APPLICANT_INFO,
        component: () => import(/* webpackChunkName: "mailerOrigination" */ '@/pages/origination/AddCoApplicantInfo.vue'),
    },
    {
        path: originationPagePaths.CO_APPLICANT_VERIFY_IDENTITY,
        component: () => import(/* webpackChunkName: "origination" */ '@/pages/origination/VerifyIdentity.vue'),
        props: { isCoApplicant: true },
    },
    {
        path: originationPagePaths.CO_APPLICANT_IDENTITY_QUESTIONS,
        component: () => import(/* webpackChunkName: "origination" */ '@/pages/origination/IdentityQuestions.vue'),
        props: { isCoApplicant: true },
    },
    {
        path: originationPagePaths.CO_APPLICANT_EXPERIAN_FROZEN,
        component: () => import(/* webpackChunkName: "coOrigination" */ '@/pages/origination/ExperianFrozen.vue'),
        meta: { transitionName: 'slide' },
        props: { isCoApplicant: true },
    },
    {
        path: originationPagePaths.FIRST_LIEN_ATTESTATION,
        component: () => import(/* webpackChunkName: "origination" */ '@/pages/origination/FirstLienAttestation.vue'),
        meta: { transitionName: 'slide' },
    },
    {
        path: originationPagePaths.PAY_STUB_VERIFICATION,
        component: () => import(/* webpackChunkName: "origination" */ '@/pages/origination/PayStubVerification.vue'),
    },
    {
        path: originationPagePaths.TAX_RETURN_VERIFICATION,
        component: () => import(/* webpackChunkName: "origination" */ '@/pages/origination/TaxReturnVerification.vue'),
    },
    {
        path: originationPagePaths.OTHER_INCOME_VERIFICATION,
        component: () => import(/* webpackChunkName: "origination" */ '@/pages/origination/OtherIncomeStatementUpload.vue'),
    },
    {
        path: originationPagePaths.FORTIFIED_INCOME_VERIFICATION,
        component: () => import(/* webpackChunkName: "origination" */ '@/pages/origination/FortifiedIncomeVerification.vue'),
    },
    {
        path: originationPagePaths.INCOME_VERIFICATION_IN_PROGRESS,
        component: () => import(/* webpackChunkName: "origination" */ '@/pages/origination/IncomeVerificationInProgress.vue'),
    },
    {
        path: originationPagePaths.INCOME_DOCUMENT_UPLOAD_PORTAL,
        component: () => import(/* webpackChunkName: "origination" */ '@/pages/origination/IncomeDocumentUploadPortal.vue'),
    },
    {
        path: originationPagePaths.STATED_INCOME,
        component: () => import(/* webpackChunkName: "origination" */ '@/pages/origination/StatedIncome.vue'),
    },
    {
        path: originationPagePaths.STATED_TOTAL_SAVINGS,
        component: () => import(/* webpackChunkName: "origination" */ '@/pages/origination/StatedTotalSavings.vue'),
    },
    {
        path: originationPagePaths.EXPERIAN_FROZEN,
        component: () => import(/* webpackChunkName: "origination" */ '@/pages/origination/ExperianFrozen.vue'),
    },
    {
        path: originationPagePaths.HMDA,
        component: () => import(/* webpackChunkName: "origination" */ '@/pages/origination/HMDA.vue'),
        meta: { transitionName: 'slide' },
    },
    {
        path: originationPagePaths.SECONDARY_SIGNERS,
        component: () => import(/* webpackChunkName: "origination" */ '@/pages/origination/SecondarySigners.vue'),
        meta: { transitionName: 'slide' },
    },
    {
        path: originationPagePaths.SECONDARY_SIGNERS_UPDATE,
        component: () => import(/* webpackChunkName: "origination" */ '@/pages/origination/SecondarySignersUpdatable.vue'),
        meta: { transitionName: 'slide' },
    },
    {
        path: originationPagePaths.DISPUTE_CO_OWNER_INFO,
        component: () => import(/* webpackChunkName: "origination" */ '@/pages/origination/DisputeCoOwnerInfo.vue'),
        meta: { transitionName: 'slide' },
    },
    {
        path: originationPagePaths.VERIFY_IDENTITY,
        component: () => import(/* webpackChunkName: "origination" */ '@/pages/origination/VerifyIdentity.vue'),
    },
    {
        path: originationPagePaths.IDENTITY_QUESTIONS,
        component: () => import(/* webpackChunkName: "origination" */ '@/pages/origination/IdentityQuestions.vue'),
    },
    {
        path: originationPagePaths.SELECT_INCOME_VERIFICATION_METHOD,
        redirect: () => {
            return { path: originationPagePaths.EMPLOYMENT_INCOME_VERIFICATION }
        },
    },
    {
        path: originationPagePaths.EMPLOYMENT_INCOME_VERIFICATION,
        component: () => import(/* webpackChunkName: "origination" */ '@/pages/origination/EmploymentIncomeVerification.vue'),
        meta: { transitionName: 'slide' },
    },
    {
        path: originationPagePaths.INCOME_VERIFICATION,
        component: () => import(/* webpackChunkName: "origination" */ '@/pages/origination/IncomeVerification.vue'),
        meta: { transitionName: 'slide' },
    },
    {
        path: originationPagePaths.RENTAL_INCOME_VERIFICATION,
        component: () => import(/* webpackChunkName: "origination" */ '@/pages/origination/nonOwnerOccupied/RentalIncomeVerification.vue'),
        meta: { transitionName: 'slide' },
    },
    {
        path: originationPagePaths.CURRENT_RENT_VERIFICATION_SELECTION,
        component: () => import(/* webpackChunkName: "origination" */ '@/pages/origination/nonOwnerOccupied/CurrentRentVerificationSelection.vue'),
        meta: { transitionName: 'slide' },
    },
    {
        path: originationPagePaths.CURRENT_RENT_VERIFICATION_DOCUMENT_UPLOAD,
        component: () => import(/* webpackChunkName: "origination" */ '@/pages/origination/nonOwnerOccupied/CurrentRentVerificationDocumentUpload.vue'),
        meta: { transitionName: 'slide' },
    },
    {
        path: originationPagePaths.DISPUTE_PROVIDER_DATA,
        component: () => import(/* webpackChunkName: "origination" */ '@/pages/origination/DisputeProviderData.vue'),
        meta: { transitionName: 'slide' },
    },
    {
        path: originationPagePaths.IRS_FOR_ALL_PQ,
        component: () => import(/* webpackChunkName: "origination" */ '@/pages/origination/IrsForAll.vue'),
        meta: { transitionName: 'slide' },
    },
    {
        path: originationPagePaths.IRS_FOR_ALL_IV,
        component: () => import(/* webpackChunkName: "origination" */ '@/pages/origination/IrsForAll.vue'),
        meta: { transitionName: 'slide' },
    },
    {
        path: originationPagePaths.SELF_EMPLOYMENT_BUSINESS_PERCENTAGE,
        component: () => import(/* webpackChunkName: "origination" */ '@/pages/origination/SelfEmploymentBusinessPercentage.vue'),
        meta: { transitionName: 'slide' },
    },
    {
        path: originationPagePaths.LINE_DRAW_APR_REDUCTION,
        component: () => import(/* webpackChunkName: "origination" */ '@/pages/origination/LineDrawAprReduction.vue'),
        meta: { transitionName: 'slide' },
    },
    {
        path: originationPagePaths.SECOND_LIEN_ATTESTATION,
        component: () => import(/* webpackChunkName: "origination" */ '@/pages/origination/SecondLienAttestation.vue'),
        meta: { transitionName: 'slide' },
    },
    {
        path: originationPagePaths.MORTGAGE_INFO_ATTESTATION,
        component: () => import(/* webpackChunkName: "origination" */ '@/pages/origination/MortgageInfoAttestation.vue'),
        meta: { transitionName: 'slide' },
    },
]

const completionRoutes: RouteConfig[] = [
    {
        path: originationPagePaths.PRE_QUALIFICATION,
        component: () => import(/* webpackChunkName: "origination" */ '@/pages/origination/PreQualification.vue'),
        meta: { transitionName: 'slide' },
    },
    {
        path: originationPagePaths.PROVIDE_SUPPORTING_INFORMATION,
        component: () => import(/* webpackChunkName: "origination" */ '@/pages/origination/ProvideSupportingInformation.vue'),
        meta: { transitionName: 'slide' },
    },
    {
        path: originationPagePaths.OFFER_PREVIEW,
        component: () => import(/* webpackChunkName: "origination" */ '@/pages/origination/OfferPreview.vue'),
        meta: { transitionName: 'slide' },
    },
    {
        path: originationPagePaths.EARLY_CREDIT_CARD_CONFIRMATION,
        component: () => import(/* webpackChunkName: "origination" */ '@/pages/origination/EarlyCreditCardConfirmation.vue'),
        meta: { transitionName: 'slide' },
    },
    {
        path: originationPagePaths.EARLY_CREDIT_CARD_RECEIVED,
        component: () => import(/* webpackChunkName: "origination" */ '@/pages/origination/EarlyCreditCardReceived.vue'),
        meta: { transitionName: 'slide' },
    },
    {
        path: originationPagePaths.AWAITING_MANUAL_DOCUMENT_VERIFICATION,
        component: () => import(/* webpackChunkName: "origination" */ '@/pages/origination/AwaitingManualDocumentVerification.vue'),
        meta: { transitionName: 'slide' },
    },
    {
        path: originationPagePaths.REMEDY_NAME_MISMATCH,
        component: () => import(/* webpackChunkName: "origination" */ '@/pages/origination/RemedyNameMismatch.vue'),
        meta: { transitionName: 'slide' },
    },
    {
        path: originationPagePaths.TRUST_INFO_SUBMISSION,
        component: () => import(/* webpackChunkName: "origination" */ '@/pages/origination/TrustInfoSubmission.vue'),
        meta: { transitionName: 'slide' },
    },
    {
        path: originationPagePaths.FORWARD_TO_NOTARIZATION,
        component: () => import(/* webpackChunkName: "origination" */ '@/pages/origination/ForwardToNotarization.vue'),
        meta: { transitionName: 'slide' },
    },
    {
        path: originationPagePaths.FLOOD_INSURANCE_VERIFICATION,
        component: () => import(/* webpackChunkName: "origination" */ '@/pages/origination/FloodInsuranceVerification.vue'),
        meta: { transitionName: 'slide' },
    },
]

export const originationRoutes: RouteConfig[] = [...dataCollectionRoutes, ...completionRoutes]
