/**
 * App Storage class
 * @description This will be responsible for storing data into the application.
 * Commonly, people use LocalStorage or SessionStorage. This is just a wrapper over them
 * because to restrict the usage of Global window storage throughout the application
 * Default, this is just using the LocalStorage
 */
import assert from 'assert'
import { inspect, logger } from '@/utils/logger'
import { merge } from 'lodash'

const sessionStorageKey = {
    basicInfo: 'basicInfo',
    sessionId: 'sessionId',
    inviteCode: 'inviteCode',
    inviteType: 'inviteType',
    pifInviteCode: 'pifInviteCode',
    pifBonus: 'pifBonus',
    pifRewardType: 'pifRewardType',
    pifForAllBonusDollars: 'pifForAllBonusDollars',
    pifSenderName: 'pifSenderName',
    agentPifInviteCode: 'agentPifInviteCode',
    agentPifShareLink: 'agentPifShareLink',
    currentFlow: 'currentFlow',
    locale: 'locale',
    applicantId: 'applicantId',
    applicantType: 'applicantType',
    loanApplicationId: 'loanApplicationId',
    primaryReturnToken: 'primaryReturnToken',
    addedCoApplicantOnFailure: 'addedCoApplicantOnFailure',
    experimentName: 'experimentName',
    ambiguousAddressData: 'amiguousAddressData',
    tcpaConsentText: 'tcpaConsentText',
    phoneNumber: 'phoneNumber',
    dateOfBirth: 'dateOfBirth',
    coApplicantPhoneNumber: 'coApplicantPhoneNumber',
    phoneHash: 'phoneHash',
    plaidLinkToken: 'plaidLinkToken',
    institutionInfo: 'institutionInfo',
    jwtTokens: 'jwtTokens',
    coApplicantJwtTokens: 'coApplicantJwtTokens',
    mloJwtTokens: 'mloJwtTokens',
    mloJwtLoanTokens: 'mloJwtLoanTokens',
    mloId: 'mloId',
    applicationData: 'applicationData',
    statedUsage: 'statedUsage',
    statedUsageAmount: 'statedUsageAmount',
    statedIncome: 'statedIncome',
    coApplicantStatedIncome: 'coApplicantStatedIncome',
    statedAnnualRentalIncome: 'statedAnnualRentalIncome',
    sessionAccessJWT: 'sessionAccessJWT',
    notaryAccessJWT: 'notaryAccessJWT',
    firstName: 'firstName',
    lastName: 'lastName',
    attestedTitleName: 'attestedTitleName',
    email: 'email',
    coApplicantFirstName: 'coApplicantFirstName',
    coApplicantLastName: 'coApplicantLastName',
    isFirstLienPosition: 'isFirstLienPosition',
    isFixedTermPaymentOffer: 'isFixedTermPaymentOffer',
    employer: 'employer',
    jobTitle: 'jobTitle',
    coApplicantJobTitle: 'coApplicantJobTitle',
    startPagePath: 'startPagePath',
    clearStorageOnNavigation: 'clearStorageOnNavigation',
    identityQA: 'identityQA',
    verifiedKba: 'verifiedKba',
    sessionRecordingInitialized: 'sessionRecordingInitialized',
    sessionRecordingUrl: 'sessionRecordingUrl',
    inviteCodeRequired: 'inviteCodeRequired',
    creditOffer: 'creditOffer',
    preQualificationOffer: 'preQualificationOffer',
    offerAcceptedAt: 'offerAcceptedAt',
    incomeVerificationCompleted: 'incomeVerificationCompleted',
    incomeVerificationMethod: 'incomeVerificationMethod',
    rentalIncomeVerificationCompleted: 'rentalIncomeVerificationMethod',
    preQualificationFailureCode: 'preQualificationFailureCode',
    payStubInfo: 'payStubInfo',
    bankStatementsInfo: 'bankStatementsInfo',
    w2StatementInfo: 'w2StatementInfo',
    profitAndLossStatementInfo: 'profitAndLossStatementInfo',
    socialSecurityInfo: 'socialSecurityInfo',
    pensionInfo: 'pensionInfo',
    retirementInfo: 'retirementInfo',
    form1099Info: 'form1099Info',
    otherIncomeLetterInfo: 'otherIncomeLetterInfo',
    taxReturnInfo: 'taxReturnInfo',
    utilityBillInfo: 'utilityBillInfo',
    insuranceInfo: 'insuranceInfo',
    floodInsuranceInfo: 'floodInsuranceInfo',
    disputeProviderDataDocInfo: 'disputeProviderDataDocInfo',
    supportingDocument: 'supportingDocument',
    incomePortalUploadedDocuments: 'incomePortalUploadedDocuments',
    alreadySubmittedHMDA: 'alreadySubmittedHMDA',
    applicantSubmittedEmployer: 'applicantSubmittedEmployer',
    coApplicantSubmittedEmployer: 'coApplicantSubmittedEmployer',
    applicantMaritalStatus: 'applicantMaritalStatus',
    coApplicantMaritalStatus: 'coApplicantMaritalStatus',
    priorApplicationFoundResponseJSON: 'priorApplicationFoundResponseJSON',
    addReview: 'addRating',
    isUnderwritingInfoUnchanged: 'isUnderwritingInfoUnchanged',
    landingWarning: 'landingWarning',
    hasExtendedLinesOffer: 'hasExtendedLinesOffer',
    landingPageOverride: 'landingPageOverride',
    isMailInviteExpired: 'isMailInviteExpired',
    preQualificationOverride: 'preQualificationOverride',
    experimentsOverrides: 'experimentsOverrides',
    returnToken2: 'returnToken2',
    secondarySignersList: 'secondarySignersList',
    underwritingMetaData: 'underwritingMetaData',
    creditCardMarketData: 'creditCardMarketData',
    abTestOverrides: 'abTestOverrides', // JSON like: [{"type": "underWritingPolicyExperiment", group: "control"}]
    notaryReviewPage: 'notaryReviewPage',
    mloApplicationPii: 'mloApplicationPii',
    mloUwJobId: 'mloUwJobId',
    employmentType: 'employmentType',
    incomeVerificationAllowedDocs: 'incomeVerificationAllowedDocs',
    isInIncomeVerification: 'isInIncomeVerification',
    isInFloodInsuranceVerification: 'isInFloodInsuranceVerification',
    residenceType: 'residenceType',

    // Auto
    autoSessionId: 'autoSessionId',
    autoSessionAccessJWT: 'autoSessionAccessJWT',
    autoExperimentOverrides: 'autoExperimentOverrides',
    autoUnderwritingMetaData: 'autoUnderwritingMetaData',

    // this overrides the timeout value for axios only used for cypress tests atm due to them being http/1.1 https://github.com/cypress-io/cypress/issues/3708
    httpTimeout: 'httpTimeout',

    // Flags
    trustsFeatureFlag: 'trustsFeatureFlag',

    // Privacy Policy Banner
    displayedPrivacyPolicyBanner: 'displayedPrivacyPolicyBanner',

    // MLO
    mloOverview: 'mloOverview',
    mloActiveLoanApplications: 'mloActiveLoanApplications',
    mloPastLoanApplications: 'mloPastLoanApplications',
    mloPrefilledApplication: 'mloPrefilledApplication',

    // Churn Retention
    retentionPqOffer: 'retentionPqOffer',
    balanceSweepDetails: 'balanceSweepDetails',

    // Non-owner occupied
    rentalIncomeInfo: 'rentalIncomeInfo',
    verifyCurrentRent: 'verifyCurrentRent',
} as const

export class AppStorage {
    storage: Storage | undefined
    inMemoryStorage: Map<string, string>

    constructor(storage?: Storage) {
        this.storage = storage
        this.inMemoryStorage = new Map()
    }

    // window.storage only accepts string
    // https://developer.mozilla.org/en-US/docs/Web/API/Storage/setItem
    setItem(key: string, value: string) {
        assert(typeof key === 'string', `AppStorage.setItem key must be a string`)
        assert(typeof value === 'string', `AppStorage.setItem value for ${key} must be a string`)

        if (key === sessionStorageKey.experimentsOverrides) {
            const enabledOverridesStr = this.getItem(sessionStorageKey.experimentsOverrides)
            if (enabledOverridesStr) {
                /*
                    We need to merge experiments with any existing loaded experiments
                    There are multiple endpoints that get experimentOverrides and each of them passes a subset of the data.
                    For example, referrer is not passed down on return applicant JODL endpoint, while phone number is not passed
                    on the creation of the session when the application is loaded.
                    This does a deep merge of the groups on every experimentation type.
                 */
                const enabledOverrides = JSON.parse(enabledOverridesStr)
                const newOverrides = JSON.parse(value)
                value = JSON.stringify(merge(enabledOverrides, newOverrides))
            }
        }

        logger.info(`set_session_storage_value [${key}: ${value}]`)

        this.inMemoryStorage.set(key, value)
        try {
            this.storage?.setItem(key, value)
        } catch (e) {
            console.log('Browser storage unavailable, failed with error:', e)
        }
    }

    /**
     * Value can be of any type, if its not a string user must provide a transformer function which returns a string
     * @param key {string}
     * @param value {any}
     * @param transformer {function}
     */
    setItemIfPresent(key: string, value: any, transformer?: (param: any) => string) {
        if (typeof value === 'undefined' || value === null) {
            console.log(`Skipping setting key ${key}`)
            return
        }

        assert(typeof value === 'string' || transformer, 'Value is not of type string and no transformer was provided')

        const finalValue = transformer ? transformer(value) : value
        return this.setItem(key, finalValue)
    }

    getItem(key: string): string | undefined {
        // Prioritize browser storage since it is shared across tabs
        let value = this.storage?.getItem(key)
        if (typeof value === 'string') {
            // Keep in sync inMemoryStorage
            this.inMemoryStorage.set(key, value)
        } else {
            value = this.inMemoryStorage.get(key)
            // Don't sync back to browser storage, otherwise deleting a key will not work when multiple tabs are open
        }

        return value ?? undefined
    }

    removeItem(key: string) {
        console.log(`Removing '${key}' from storage`)
        logger.info(`remove_session_storage_value [${key}]`)
        this.inMemoryStorage.delete(key)
        this.storage?.removeItem(key)
    }

    clear() {
        console.log('Clearing storage contents')
        // log storage clearing. a snap shot of the entire storage before the change is taken
        // in logEvent
        window.logEvent('clear_session_storage')
        this.inMemoryStorage.clear()
        this.storage?.clear()
    }

    /** @param exceptedKeyList string[] The keys we want to keep */
    clearWithException(exceptedKeyList: string[]) {
        console.log(`Clearing storage contents except: ${exceptedKeyList.join(', ')}`)
        const clearKeys: string[] = []

        // clear session id last so we can link logs by sessionId
        Object.keys(this.getAll())
            .filter((key) => key !== sessionStorageKey.sessionId)
            .forEach((key) => {
                if (!exceptedKeyList.includes(key)) {
                    clearKeys.push(key)
                }
            })
        clearKeys.forEach((key) => this.removeItem(key))

        if (this.getItem(sessionStorageKey.sessionId)) {
            this.removeItem(sessionStorageKey.sessionId)
        }
    }

    getAll(): { [key: string]: string } {
        const inMemoryStorageObj = Object.fromEntries(this.inMemoryStorage)
        const browserStorageObj = Object.assign({}, this.storage)
        console.debug(`In memory storage contents: ${inspect(inMemoryStorageObj)}`)
        console.debug(`Browser storage contents: ${inspect(browserStorageObj)}`)
        return Object.assign(browserStorageObj, inMemoryStorageObj)
    }
}

/**
 * Creating the instances of storage.
 */
const appLocalStorage = new AppStorage(window.localStorage)
const appSessionStorage = new AppStorage(window.sessionStorage)

if (process.env.NODE_ENV === 'development') {
    // TODO: See if there is demand to access this in other non prod envs
    /**
     * Enable direct access for dev purposes.
     * This is meant to be called from the browser console or for external tools to interact with
     * storage.
     * This should NEVER be used directly by application code, the use of @ts-ignore is deliberate
     * because of this so we don't invite devs to use this in code.
     *
     * TL;DR:
     * PLEASE DO NOT ACCESS THIS IN CODE, ADD TYPINGS, ETC...
     */
    // @ts-ignore
    window.appLocalStorage = appLocalStorage
    // @ts-ignore
    window.appSessionStorage = appSessionStorage
}

export { appLocalStorage, appSessionStorage, sessionStorageKey }
