const routeName: string = 'broker'

export const mloPageNames = {
    MLO_LOGIN: 'mloLogin',
    MLO_DASHBOARD: 'mloDashboard',
    MLO_ACCOUNT: 'mloAccount',
    MLO_PAYMENT_CALCULATOR: 'mloPaymentCalculator',
    MLO_APPLICATION_NEW_PREFILLED: 'mloApplicationNewPrefilled',
    MLO_APPLICATION_CONSENT_PERSONAL_INFO: 'mloConsentPersonalInfo',
    MLO_APPLICATION_CONSENT_EMAIL_SENT: 'mloConsentEmailSent',
    MLO_APPLICATION_PERSONAL_INFO: 'mloApplicationPersonalInfo',
    MLO_APPLICATION_UW_VALUATION: 'mloApplicationUWValuation',
    MLO_APPLICATION_FLOOD_DETERMINATION: 'mloApplicationFloodDetermination',
    MLO_APPLICATION_REPORT: 'mloApplicationReport',
    MLO_APPLICATION_PQ_CHOICE_PREVIEW: 'mloApplicationPQChoicePreview',
    MLO_APPLICATION_PQ_OVERVIEW: 'mloApplicationPQOverview',
    MLO_APPLICATION_STATUS: 'mloApplicationStatus',
    MLO_APPLICATION_DISPUTE: 'mloApplicationDispute',
    MLO_APPLICATION_ADVERSE_ACTION: 'mloApplicationAdverseAction',
    MLO_APPLICATION_DENIED: 'mloApplicationDenied',
    MLO_APPLICATION_THANKS: 'mloApplicationThanks',
}

export const mloRoutePaths = {
    MLO_LOGIN: `/${routeName}/login`,
    MLO_DASHBOARD: `/${routeName}`,
    MLO_ACCOUNT: `/${routeName}/account`,
    MLO_PAYMENT_CALCULATOR: `/${routeName}/payment-calculator`,
    MLO_APPLICATION_NEW_PREFILLED: `/${routeName}/application/new-prefilled`,
    MLO_APPLICATION_CONSENT_PERSONAL_INFO: `/${routeName}/application/:loanApplicationId/consent-personal-info`,
    MLO_APPLICATION_CONSENT_EMAIL_SENT: `/${routeName}/application/:loanApplicationId/consent-email-sent`,
    MLO_APPLICATION_PERSONAL_INFO: `/${routeName}/application/:loanApplicationId/personal-info`,
    MLO_APPLICATION_UW_VALUATION: `/${routeName}/application/:loanApplicationId/uw-valuation`,
    MLO_APPLICATION_FLOOD_DETERMINATION: `/${routeName}/application/:loanApplicationId/flood-determination`,
    MLO_APPLICATION_REPORT: `/${routeName}/application/:loanApplicationId/report`,
    MLO_APPLICATION_PQ_CHOICE_PREVIEW: `/${routeName}/application/:loanApplicationId/pq-choice-preview`,
    MLO_APPLICATION_PQ_OVERVIEW: `/${routeName}/application/:loanApplicationId/pq-overview`,
    MLO_APPLICATION_STATUS: `/${routeName}/application/:loanApplicationId/status`,
    MLO_APPLICATION_DISPUTE: `/${routeName}/dispute`,
    MLO_APPLICATION_ADVERSE_ACTION: `/${routeName}/:loanApplicationId/adverse-action`,
    MLO_APPLICATION_DENIED: `/${routeName}/denied`,
    MLO_APPLICATION_THANKS: `/${routeName}/application/:loanApplicationId/thanks`,
}

export const mloRoutes = [
    {
        path: mloRoutePaths.MLO_LOGIN,
        name: mloPageNames.MLO_LOGIN,
        component: () => import(/* webpackChunkName: "origination-mlo" */ '@/pages/mlo/Login.vue'),
        meta: { public: true },
        // When props is set to true, the route.params will be set as the component props.
        props: true,
    },
    {
        path: mloRoutePaths.MLO_DASHBOARD,
        name: mloPageNames.MLO_DASHBOARD,
        component: () => import(/* webpackChunkName: "origination-mlo" */ '@/pages/mlo/Dashboard.vue'),
        meta: { public: false },
        // When props is set to true, the route.params will be set as the component props.
        props: true,
    },
    {
        path: mloRoutePaths.MLO_ACCOUNT,
        name: mloPageNames.MLO_ACCOUNT,
        component: () => import(/* webpackChunkName: "origination-mlo" */ '@/pages/mlo/Account.vue'),
        meta: { public: false },
        // When props is set to true, the route.params will be set as the component props.
        props: true,
    },
    {
        path: mloRoutePaths.MLO_PAYMENT_CALCULATOR,
        name: mloPageNames.MLO_PAYMENT_CALCULATOR,
        component: () => import(/* webpackChunkName: "origination-mlo" */ '@/pages/mlo/MloPaymentCalculator.vue'),
        meta: { public: false },
        // When props is set to true, the route.params will be set as the component props.
        props: true,
    },
    {
        path: mloRoutePaths.MLO_APPLICATION_NEW_PREFILLED,
        name: mloPageNames.MLO_APPLICATION_NEW_PREFILLED,
        component: () => import(/* webpackChunkName: "origination-mlo" */ '@/pages/mlo/ApplicationNewPrefilled.vue'),
        meta: { public: true },
        // When props is set to true, the route.params will be set as the component props.
        props: true,
    },
    {
        path: mloRoutePaths.MLO_APPLICATION_CONSENT_PERSONAL_INFO,
        name: mloPageNames.MLO_APPLICATION_CONSENT_PERSONAL_INFO,
        component: () => import(/* webpackChunkName: "origination-mlo" */ '@/pages/mlo/ApplicationConsent.vue'),
        meta: { public: false },
        // When props is set to true, the route.params will be set as the component props.
        props: true,
    },
    {
        path: mloRoutePaths.MLO_APPLICATION_CONSENT_EMAIL_SENT,
        name: mloPageNames.MLO_APPLICATION_CONSENT_EMAIL_SENT,
        component: () => import(/* webpackChunkName: "origination-mlo" */ '@/pages/mlo/ApplicationConsentEmailSent.vue'),
        meta: { public: false },
        // When props is set to true, the route.params will be set as the component props.
        props: true,
    },
    {
        path: mloRoutePaths.MLO_APPLICATION_PERSONAL_INFO,
        name: mloPageNames.MLO_APPLICATION_PERSONAL_INFO,
        component: () => import(/* webpackChunkName: "origination-mlo" */ '@/pages/mlo/ApplicationPii.vue'),
        meta: { public: false },
        // When props is set to true, the route.params will be set as the component props.
        props: true,
    },
    {
        path: mloRoutePaths.MLO_APPLICATION_UW_VALUATION,
        name: mloPageNames.MLO_APPLICATION_UW_VALUATION,
        meta: { public: false },
        component: () => import(/* webpackChunkName: "origination-mlo" */ '@/pages/mlo/ApplicationUwValuation.vue'),
        // When props is set to true, the route.params will be set as the component props.
        props: true,
    },
    {
        path: mloRoutePaths.MLO_APPLICATION_FLOOD_DETERMINATION,
        name: mloPageNames.MLO_APPLICATION_FLOOD_DETERMINATION,
        meta: { public: false },
        component: () => import(/* webpackChunkName: "origination-mlo" */ '@/pages/mlo/ApplicationFloodDetermination.vue'),
        // When props is set to true, the route.params will be set as the component props.
        props: true,
    },
    {
        path: mloRoutePaths.MLO_APPLICATION_REPORT,
        name: mloPageNames.MLO_APPLICATION_REPORT,
        meta: { public: false },
        component: () => import(/* webpackChunkName: "origination-mlo" */ '@/pages/mlo/ApplicationReports.vue'),
        // When props is set to true, the route.params will be set as the component props.
        props: true,
    },
    {
        path: mloRoutePaths.MLO_APPLICATION_PQ_CHOICE_PREVIEW,
        name: mloPageNames.MLO_APPLICATION_PQ_CHOICE_PREVIEW,
        component: () => import(/* webpackChunkName: "origination-mlo" */ '@/pages/mlo/ApplicationPqChoicePreview.vue'),
        meta: { public: false },
        // When props is set to true, the route.params will be set as the component props.
        props: true,
    },
    {
        path: mloRoutePaths.MLO_APPLICATION_PQ_OVERVIEW,
        name: mloPageNames.MLO_APPLICATION_PQ_OVERVIEW,
        component: () => import(/* webpackChunkName: "origination-mlo" */ '@/pages/mlo/ApplicationPqOverview.vue'),
        meta: { public: false },
        // When props is set to true, the route.params will be set as the component props.
        props: true,
    },
    {
        path: mloRoutePaths.MLO_APPLICATION_STATUS,
        name: mloPageNames.MLO_APPLICATION_STATUS,
        component: () => import(/* webpackChunkName: "origination-mlo" */ '@/pages/mlo/ApplicationStatus.vue'),
        meta: { public: false },
        // When props is set to true, the route.params will be set as the component props.
        props: true,
    },
    {
        path: mloRoutePaths.MLO_APPLICATION_DISPUTE,
        name: mloPageNames.MLO_APPLICATION_DISPUTE,
        component: () => import(/* webpackChunkName: "origination-mlo" */ '@/pages/mlo/ApplicationDispute.vue'),
        meta: { public: false },
        // When props is set to true, the route.params will be set as the component props.
        props: true,
    },
    {
        path: mloRoutePaths.MLO_APPLICATION_ADVERSE_ACTION,
        name: mloPageNames.MLO_APPLICATION_ADVERSE_ACTION,
        component: () => import(/* webpackChunkName: "origination-mlo" */ '@/pages/mlo/ApplicationAAN.vue'),
        meta: { public: false },
        // When props is set to true, the route.params will be set as the component props.
        props: true,
    },
    {
        path: mloRoutePaths.MLO_APPLICATION_DENIED,
        name: mloPageNames.MLO_APPLICATION_DENIED,
        component: () => import(/* webpackChunkName: "origination-mlo" */ '@/pages/mlo/ApplicationDenied.vue'),
        meta: { public: false },
        // When props is set to true, the route.params will be set as the component props.
        props: true,
    },
    {
        path: mloRoutePaths.MLO_APPLICATION_THANKS,
        name: mloPageNames.MLO_APPLICATION_THANKS,
        component: () => import(/* webpackChunkName: "origination-mlo" */ '@/pages/mlo/ApplicationThanks.vue'),
        meta: { public: false },
        // When props is set to true, the route.params will be set as the component props.
        props: true,
    },
]
