"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ErrorCode = void 0;
// ErrorCode to share everywhere and reduce replications
// Do NOT put anything secret here
var ErrorCode;
(function (ErrorCode) {
    ErrorCode["HOME_NOT_FOUND"] = "HOME_NOT_FOUND";
    ErrorCode["INVALID_FLOOD_ZONE"] = "INVALID_FLOOD_ZONE";
    ErrorCode["UNKNOWN_FLOOD_ZONE"] = "UNKNOWN_FLOOD_ZONE";
    ErrorCode["SSN_COMPLETION_ERROR"] = "SSN_COMPLETION_ERROR";
    ErrorCode["SSN_MATCHES_PRIMARY_APPLICANT"] = "SSN_MATCHES_PRIMARY_APPLICANT";
    ErrorCode["PRIOR_APPLICATION_FOUND_FOR_COAPPLICANT"] = "PRIOR_APPLICATION_FOUND_FOR_COAPPLICANT";
})(ErrorCode = exports.ErrorCode || (exports.ErrorCode = {}));
