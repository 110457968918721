import { appSessionStorage, sessionStorageKey } from '@/utils/storage'
import { sharedPagePaths } from '@/routes/sharedRoutes'
import { RouteOption } from '@/flow/flowUtility'
import { logger } from '@/utils/logger'
import { getOriginationNextRouteWithCurrentPath } from '@/flow/originationFlow'
import { hmdaNextRouteWithCurrentPath } from '@/flow/hmdaFlow'
import { getMloNextRouteWithCurrentPath } from '@/flow/mloFlow'
import { getCreditLimitIncreaseNextRouteWithCurrentPath } from '@/flow/creditLimitIncreaseFlow'
import { getStatedUsageLandingNextRouteWithCurrentPath } from '@/flow/statedUsageLanding'
import { getMloApplicantNextRouteWithCurrentPath } from '@/flow/mloApplicantFlow'
import { getNextPifForAllRouteWithCurrentPath } from '@/flow/pifForAllFlow'
import { getCliAndAprReductionAndBalanceSweepNextRouteWithCurrentPath } from '@/flow/cliAndAprReductionAndBalanceSweepFlow'

export enum Flows {
    origination = 'origination',
    pifForAll = 'pifForAll',
    statedUsageLanding = 'statedUsageLanding',
    hmda = 'hmda',
    mlo = 'mlo', // The mlo agent itself
    mloApplicant = 'mloApplicant', // An applicant of the mlo agent
    creditLimitIncrease = 'creditLimitIncrease',
    cliAndAprReductionAndBalanceSweep = 'cliAndAprReductionAndBalanceSweep',

    _realEstateAgent = 'realEstateAgent',
}

export let latestPath = 'initializationPath'

export const getNextRoute = (pageInstance: { pageRoute: string }, routeOption?: RouteOption): string => {
    const nextPath = getNextPath(pageInstance.pageRoute, routeOption)
    latestPath = nextPath
    return nextPath
}

const flowToPathGetterMap: Record<Flows, (currPath: string, routeOption?: RouteOption) => string | null> = {
    [Flows.origination]: getOriginationNextRouteWithCurrentPath,
    [Flows.hmda]: hmdaNextRouteWithCurrentPath,
    [Flows.mlo]: getMloNextRouteWithCurrentPath,
    [Flows.mloApplicant]: getMloApplicantNextRouteWithCurrentPath,
    [Flows.creditLimitIncrease]: getCreditLimitIncreaseNextRouteWithCurrentPath,
    [Flows.statedUsageLanding]: getStatedUsageLandingNextRouteWithCurrentPath,
    [Flows.pifForAll]: getNextPifForAllRouteWithCurrentPath,
    [Flows.cliAndAprReductionAndBalanceSweep]: getCliAndAprReductionAndBalanceSweepNextRouteWithCurrentPath,

    // Not implemented flows
    [Flows._realEstateAgent]: () => null,
}

export const getNextPath = (currPath: string, routeOption?: RouteOption): string => {
    const currentFlow = appSessionStorage.getItem(sessionStorageKey.currentFlow) as Flows | null

    if (!currentFlow) {
        logger.info('flow not found, could not determine next page')
        return sharedPagePaths.PAGE_NOT_FOUND
    }

    const pathGetter = flowToPathGetterMap[currentFlow]

    const nextPath = pathGetter(currPath, routeOption)
    return nextPath || sharedPagePaths.PAGE_NOT_FOUND
}
