// Constants
export const ESTIMATED_CARD_MAIL_ARRIVAL_BUSINESS_DAYS = '5-10'

// Keep in sync with LoanApplicationType in backend
export enum LoanApplicationType {
    AVEN_ORIGINATION = 'AVEN_ORIGINATION',
    AVEN_MLO_ORIGINATION = 'AVEN_MLO_ORIGINATION',
    AVEN_HOME_REATTACH = 'AVEN_HOME_REATTACH',
    AVEN_HOME_REUNDERWRITING_LINE_SIZE_INCREASE = 'AVEN_HOME_REUNDERWRITING_LINE_SIZE_INCREASE',
    AVEN_HOME_CLI_AND_APR_REDUCTION_AND_BALANCE_SWEEP = 'AVEN_HOME_CLI_AND_APR_REDUCTION_AND_BALANCE_SWEEP',
}

// Keep in sync with LoanApplicationStatus in backend
export enum LoanApplicationStatus {
    pending = 'pending',
    preQualified = 'preQualified',
    preQualificationAccepted = 'preQualificationAccepted',
    incomeVerification = 'incomeVerification',
    incomeVerified = 'incomeVerified',
    floodInsuranceVerification = 'floodInsuranceVerification',
    floodInsuranceVerified = 'floodInsuranceVerified',
    offered = 'offered',
    accepted = 'accepted',
    awaitingSuccessfulNotarizations = 'awaitingSuccessfulNotarizations',
    notarizationsComplete = 'notarizationsComplete',
    withdrawn = 'withdrawn',
    declined = 'declined',
    approved = 'approved',
    denied = 'denied',
    blocked = 'blocked',
    trialDenialDm = 'trialDenialDm',
    deniedIndividualCanAddCoApplicant = 'deniedIndividualCanAddCoApplicant',
    humanInvestigate = 'humanInvestigate',
    ineligible = 'ineligible',
    incomplete = 'incomplete',
    offeredNotApproved = 'offeredNotApproved',
    duplicate = 'duplicate',
    mobileNotary = 'mobileNotary', // application is in a county that requires RIN
}

// Keep in sync with terminalLoanApplicationStatuses in backend (loanApplicationHelper.ts)
export const terminalLoanApplicationStatuses: readonly LoanApplicationStatus[] = [
    LoanApplicationStatus.incomplete,
    LoanApplicationStatus.offeredNotApproved,
    LoanApplicationStatus.duplicate,
    LoanApplicationStatus.ineligible,
    LoanApplicationStatus.denied,
    LoanApplicationStatus.deniedIndividualCanAddCoApplicant, // we create new LAs for applicants who reach this status so it is still terminal
    LoanApplicationStatus.approved,
    LoanApplicationStatus.blocked,
    LoanApplicationStatus.withdrawn,
    LoanApplicationStatus.declined,
] as const

// Keep in sync with activeLoanApplicationStatuses in backend (loanApplicationHelper.ts)
export const activeLoanApplicationStatuses: LoanApplicationStatus[] = Object.values(LoanApplicationStatus).filter(
    (status) => !terminalLoanApplicationStatuses.concat([LoanApplicationStatus.trialDenialDm]).includes(status)
)

// Keep in sync with terminalNonApprovedLoanApplicationStatuses in backend (loanApplicationHelper.ts)
export const terminalNonApprovedLoanApplicationStatuses: LoanApplicationStatus[] = Object.values(terminalLoanApplicationStatuses).filter((status) => status !== LoanApplicationStatus.approved)

// Keep in sync with backend (loanApplication.ts)
export enum ConsentToMloOriginationStatus {
    pending = 'pending',
    consentEmailSent = 'consentEmailSent',
    consented = 'consented',
    declined = 'declined',
}

// Keep in sync with ApplicantType in aven_backend/src/entity/applicant.ts
export enum ApplicantType {
    primary = 'primary', // i.e. regular applicant
    coApplicant = 'coApplicant', // is underwritten along with primary
    coOwner = 'coOwner', // purely for signature purposes
    livingTrustMember = 'livingTrustMember', // purely for signature purposes
}

// Keep in sync with NextHomeApplicationAction in aven_backend/src/controller/homeOriginationRoutingManager.types.ts
export enum NextHomeApplicationAction {
    // Application should be redirected to Stated Usage page
    STATED_USAGE = 'statedUsage',
    // Applicant should be redirected to Personal Info page
    PERSONAL_INFO = 'personalInfo',
    STATED_INCOME = 'statedIncome',
    // Applicant should be redirected to Identity Verification page
    VERIFY_IDENTITY = 'verifyIdentity',
    // Applicant should be directed to the Thanks page
    THANKS = 'thanks',
    // Applicant should be redirected to page to add co applicant (because indiv denied on DTI alone)
    OFFER_ADD_COAPPLICANT = 'offerAddCoApplicant',
    // Applicant can diuspute the denial
    DISPUTE_DENIAL = 'disputeDenial',
    // Applicant should view the pre-qual page
    PRE_QUALIFICATION = 'preQualification',
    // Applicant should view the hmda questionnaire
    HMDA = 'hmda',
    // Applicant should view the appropriate income verification page
    EMPLOYMENT_INCOME_VERIFICATION = 'employmentIncomeVerification',
    INCOME_VERIFICATION_TAX_RETURN = 'incomeVerificationTaxReturn',
    INCOME_VERIFICATION_BANK_STATEMENT = 'incomeVerificationBankStatement',
    MORTGAGE_STATEMENT = 'mortgageStatement',
    INCOME_VERIFICATION_PAY_STUB = 'incomeVerificationPayStub',
    INCOME_VERIFICATION_IRS_FORM = 'incomeVerificationIrsForm',
    INCOME_VERIFICATION_DOCS_UPLOADED_AND_PROCESSED = 'incomeVerificationDocsUploadedAndProcessed',
    INCOME_VERIFICATION_DOCS_UPLOADED_BUT_UNDER_PROCEESSING = 'incomeVerificationDocsUploadedButUnderProcessing',
    INCOME_DOCUMENT_UPLOAD_PORTAL = 'incomeDocumentUploadPortal',
    CURRENT_RENT_VERIFICATION_SELECTION = 'currentRentVerificationSelection',
    // Applicant should view the offer preview page
    OFFER_PREVIEW = 'offerPreview',
    // Applicant should view the appropriate flood insurance verification page
    FLOOD_INSURANCE_VERIFICATION = 'floodInsuranceVerification',
    // Applicant should be redirected to either coOwner page OR trust page
    // i.e. whatever they would normally see after accepting an offer
    POST_ACCEPT = 'postAccept',
    // Applicant should be directed to the notary product
    NOTARIZATION = 'notarization',
    // Applicant is already a customer, so they should view the Welcome page
    WELCOME = 'welcome',
    DENIED = 'denied',
    PENDING = 'pending',
    REVIEW = 'review',
    COOWNER_DISPUTE = 'coownerDispute',
    REVIEW_UPLOADED_DOC = 'reviewUploadedDoc',
    UNSUPPORTED_STATE = 'unsupportedState',
    // Applicant has closed their application (withdrawn/declined)
    CLOSED = 'closed',
    // In Churn Retention flow, applicant will see the page for Balance Sweep
    BALANCE_SWEEP = 'balance_sweep',
    // In Churn retention flow, if there is no CLI, user would skip notary and go to docusign page
    CHURN_RETENTION_DOCUSIGN = 'churn_retention_docusign',
    // In Churn retention flow, it could be that user has no CLI offer and has been in approved status,
    // we would move user to a new welcome page in this scenario
    CHURN_RETENTION_SKIP_NOTARY_WELCOME = 'churn_retention_skip_notary_welcome',
    // Applicant is waiting for pre-approval credit card to be sent
    EARLY_CREDIT_CARD_CONFIRMATION_PAGE = 'early_credit_card_confirmation_page',
    // Applicant has received the pre-approval credit card, move forward
    EARLY_CREDIT_CARD_RECEIVED_PAGE = 'early_credit_card_received_page',
    // lien attestation page for first lien position
    FIRST_LIEN_ATTESTATION_PAGE = 'first_lien_attestation_page',
}

export const CASH_OUT_TRXN_FEE_PERCENTAGE = 2.5
export const INSTALLMENT_PLAN_APR_MOD = 3.5

// debounce time in milliseconds
export const BUTTON_CLICK_DEBOUNCE_TIME_MSEC = 500

// sync with enum AccountClosureReason in aven_backend/src/entity/accountClosureRequest.ts
export enum AccountClosureReason {
    LOWER_MONTHLY_PAYMENTS = 'lower_monthly_payment',
    LOWER_APR = 'lower_apr',
    ACCESS_MORE_HOME_EQUITY = 'access_more_home_equity',
    SELLING_HOME = 'selling_home',
    NOT_HAPPY = 'not_happy',
    NO_REASON_STATED = 'no_reason_stated',
    LINE_SIZE_TOO_LOW = 'line_size_too_low',
    NOT_USE = 'not_use',
    OTHER = 'other',
    DEPRECATED_REFI = 'refi',
    DEPRECATED_CASH_OUT_REFI = 'cash_out_refi',

    // for account being detected as fraud, we still need to go through the regular account closure procedure (eg reconveyance, etc)
    FRAUD = 'fraud',
    // account is being closed by aven because contingency was not cleared
    CONTINGENCY_REJECTED = 'contingency_rejected',
}

// sync with enum WithdrawOrDeclineReason in aven_backend/src/entity/customerSurveyResponse.types.ts
export enum WithdrawOrDeclineReason {
    HIGH_MONTHLY_PAYMENTS = 'HIGH_MONTHLY_PAYMENTS',
    HIGH_APR = 'HIGH_APR',
    LOW_LINE_SIZE = 'LOW_LINE_SIZE',
    OTHER = 'OTHER',
    DO_NOT_TRUST = 'DO_NOT_TRUST',
    TOO_MUCH_WORK = 'TOO_MUCH_WORK',
}

// sync with enum CustomerSurveyType in aven_backend/src/entity/customerSurveyResponse.types.ts
export enum CustomerSurveyType {
    WithdrawOrDecline = 'WithdrawOrDecline',
}
